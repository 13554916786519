section#questions {
  background: url(../img/background.jpg) center no-repeat;
  position: relative;
  z-index: 99;
  margin-top: 80px;
}

section#questions .questions {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

section#questions .questions .questions-main {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(83.33333333333334% - 30px);
}

section#questions .questions .questions-main .content {
  margin: 0 auto;
  padding: 0 60px 0 0;
}

section#questions .questions .questions-main .question {
  position: relative;
  background: #000;
  height: 20px;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 1.8rem;
  height: 30px;
  line-height: 30px;
}

section#questions .questions .questions-main .answers {
  background: #181818;
  color: #35cbc8;
  padding: 0px 15px;
  width: 100%;
  margin: 5px 0;
  height: 0;
  line-height: 20px;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .7s ease;
  -moz-transition: .7s ease;
  -o-transition: .7s ease;
  transition: .7s ease;
  font-size: 1.8rem;
}

section#questions .questions .questions-main .questions:checked ~ .answers {
  height: auto;
  opacity: 1;
  padding: 15px;
}

section#questions .questions .questions-main .plus {
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  z-index: 5;
  line-height: 100%;
  font-size: 4rem;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

section#questions .questions .questions-main .questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

section#questions .questions .questions-main .questions {
  display: none;
}

section#questions .questions .questions-bmw img {
  position: absolute;
  max-width: 500px;
  bottom: 0;
  right: 0;
  z-index: -5;
}

section#price {
  margin-top: 80px;
}

section#price p {
  color: #000;
}

section#price a {
  font-size: 2rem;
  color: #35cbc8;
}

section#price a:hover {
  color: #fefefe;
}

section#price .container .cars-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
}

section#price .container .cars-wrapper .car-wrapper-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(16.666666666666668% - 30px);
  margin: 15px;
  display: flex;
  justify-content: center;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: all ease .2s;
}

section#price .container .cars-wrapper .car-wrapper-item:hover {
  filter: none;
  opacity: 1;
}

section#price .container .cars-wrapper .car-wrapper-item img {
  max-width: 150px;
}

section#price .container .price-stages {
  margin: 20px 0;
  color: #000;
}

section#price .container .price-stages li {
  padding: 5px 0;
}

.btn-slide {
  padding: 15px 60px;
  font-size: 2rem;
  cursor: pointer;
  color: #000;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #000;
  transition: all .4s;
}

.btn-slide:hover {
  border: 2px solid #35cbc8;
  opacity: 0.8;
  color: #000;
}

section#sales {
  margin-top: 80px;
  width: 100%;
}

section#sales .container .sales-today p {
  color: #000;
  margin-bottom: 20px;
}

section#sales .container .sales-today h3 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

section#sales .container .sales-today .sales-today-item {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

section#sales .container .sales-today .sales-today-item .slide-description {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

section#sales .container .sales-today .sales-today-item .slide-img {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

section#sales .container .sales-today .sales-today-item .slide-img img {
  margin: 30px 0;
}

section#sales-ended-section {
  background-color: #fefefe;
}

section#sales-ended-section .sales-ended {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

section#sales-ended-section .sales-ended .sales-ended-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  margin: 10px;
  text-align: center;
  border: 2px solid #000;
  padding: 10px;
}

section#sales-ended-section .sales-ended .sales-ended-item h3 {
  margin: 10px 0;
}

section#sales-ended-section .sales-ended .sales-ended-item p {
  margin: 10px 0;
  color: #000;
}

* {
  font-size: 2rem;
}

section#car-price {
  margin-top: 80px;
}

section#car-price a {
  color: #35cbc8;
  font-size: 2rem;
  transition: all .6s;
}

section#car-price a:hover {
  color: #fefefe;
}

section#car-price .car-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

section#car-price .car-logo img {
  max-width: 150px;
  max-height: 150px;
}

section#car-price table {
  margin: 0 auto;
}

section#car-price table td,
section#car-price table th {
  padding: 5px 20px;
  border: 2px solid #181818;
  text-align: center;
}

section#car-price table td {
  color: #181818;
}

section#car-price table .table-names {
  font-size: 2rem;
  color: #35cbc8;
  text-transform: uppercase;
}

section#car-price .btn-container {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
}

section#car-price .btn-container .btn-zapis {
  padding: 15px 60px;
  font-size: 2rem;
  border-radius: 5px;
  background-color: #000;
  border: 2px solid transparent;
}

section#car-price .btn-container .btn-zapis:hover {
  opacity: 1;
  background-color: #ebebeb;
  color: #000;
  border: 2px solid #000;
}

section#car-price .car-chip-about {
  color: #181818;
  margin-top: 50px;
}

section#car-price .car-chip-about p {
  margin: 10px 0;
}

section#car-price .car-chip-about ul {
  padding: 10px 0;
}

section#car-price .car-chip-about li {
  padding: 5px 0;
}

section#car-price .car-chip-about h3 {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

section#car-price .car-not-found {
  color: #181818;
  padding: 10px 0;
}

section#car-price .oferta ul {
  padding: 10px 0;
}

section#car-price .oferta li {
  color: #181818;
  padding: 5px 0;
  font-size: 15px;
}

.wcm_button {
  background-color: #000;
}

.error-page {
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
  position: relative;
}

.error-page h1 {
  text-align: center;
}

.error-page a {
  font-size: 3rem;
}

.error-page .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.error-page img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 500px;
}

a {
  color: #181818;
}

a:hover {
  color: #35cbc8;
}

.iziModal-wrap {
  background-color: #181818;
}

.iziModal-wrap .iziModal-content p {
  padding: 20px;
}

.iziModal .iziModal-header-subtitle,
.iziModal .iziModal-header-title {
  font-family: 'Arimo', sans-serif;
}

section#reviews {
  margin-top: 80px;
}

section#reviews .reviews-items .reviews-item {
  margin: 0;
  padding: 0;
  width: 16.6%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

section#reviews .reviews-items .reviews-item--width2 {
  width: 40%;
}

section#reviews .reviews-items .reviews-item img {
  max-width: 100%;
  filter: grayscale(1);
}

section#reviews .reviews-items .reviews-item .client-info {
  position: absolute;
  text-align: center;
  display: none;
  width: 50%;
  padding: 10px;
  background-color: #181818;
}

section#reviews .reviews-items .reviews-item .client-info a {
  padding: 5px 0;
}

section#reviews .reviews-items .reviews-item:hover .client-info {
  display: block;
}

section#reviews .reviews-vk {
  text-align: center;
  padding-top: 60px;
}

section#reviews .reviews-vk a {
  font-size: 2.5rem;
  color: #181818;
}

section#reviews .reviews-vk a:hover {
  color: #35cbc8;
}

section#reviews .reviews-vk h3 {
  padding-bottom: 30px;
}

section#reviews .reviews-vk #vk_comments {
  margin: 0 auto;
}

.contacts-page {
  margin-top: 80px;
}

section#blog {
  margin-top: 80px;
}

section#blog .item-article {
  margin: 20px 0;
}

section#blog .item-article .item-article-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #181818;
  margin: 20px 0;
  padding: 30px;
}

section#blog .item-article .item-article-container .article-container-img {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

section#blog .item-article .item-article-container .article-container-text {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
}

section#blog .item-article .item-article-container .article-container-text h3 {
  margin-bottom: 10px;
}

section#blog .item-article .item-article-container .article-container-text a {
  color: #35cbc8;
  font-size: 2.2rem;
}

section#blog .item-article .item-article-container .article-container-text a:hover {
  color: #fefefe;
}

section#blog .item-article .item-article-container .article-container-text .read-article {
  margin-top: 10px;
}

section#article {
  margin-top: 80px;
}

section#article a {
  color: #35cbc8;
  font-size: 2rem;
  transition: all .6s;
}

section#article a:hover {
  color: #fefefe;
}

section#article .container .article-img img {
  display: block;
  max-width: 700px;
  margin: 0 auto;
}

section#article .container .article-text {
  margin-top: 40px;
  color: #181818;
}

section#article .container .article-text h3 {
  padding: 20px 0;
}

section#article .container .article-text p {
  color: #181818;
}

section#article .container .article-text li {
  margin: 10px;
  margin-left: 30px;
  list-style: disc;
}

section#article .container .back-link {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

section#article .container .back-link img {
  width: 40px;
  margin-right: 10px;
}

section#article .container #vk_comments {
  margin-top: 20px;
}

.loaderArea {
  background: #000;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
}

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
}

.load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child {
  background: #35cbc8;
  animation-delay: -1.5s;
}

.load :nth-child(2) {
  background: #fefefe;
  animation-delay: -1s;
}

.load :nth-child(3) {
  background: #35cbc8;
  animation-delay: -0.5s;
}

.load :last-child {
  background: #fefefe;
}

@keyframes spin {
  0%, 100% {
    transform: translate(0);
  }

  25% {
    transform: translate(160%);
  }

  50% {
    transform: translate(160%, 160%);
  }

  75% {
    transform: translate(0, 160%);
  }
}

.mask {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
}

.mask-opened {
  display: block;
}

.modal-vk-wrapper {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}

.modal-vk-wrapper .modal-vk {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 150px;
  width: 400px;
  color: #35cbc8;
  transform: translate(-50%, -50%);
  background-color: #181818;
  padding: 35px;
}

.modal-vk-wrapper .modal-vk p {
  text-align: center;
}

.modal-vk-wrapper .modal-vk p .modal-vk-salenumber {
  font-size: 4rem;
  color: #35cbc8;
}

.modal-vk-wrapper .modal-vk .modal-vk-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.modal-vk-wrapper .modal-vk .modal-vk-link img {
  width: 30px;
  margin-right: 10px;
}

.modal-vk-wrapper .modal-vk .modal-vk-link a {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.modal-vk-wrapper .modal-vk .modal-close {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fefefe;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  cursor: pointer;
}

.modal-vk-wrapper .modal-vk .modal-close:hover {
  background-color: #35cbc8;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/FiraSans-Regular.eot');
  src: url('../fonts/FiraSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/FiraSans-Regular.woff') format('woff'), url('../fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans Bold';
  src: url('../fonts/FiraSans-Bold.eot');
  src: url('../fonts/FiraSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/FiraSans-Bold.woff') format('woff'), url('../fonts/FiraSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans Italic';
  src: url('../fonts/FiraSans-MediumItalic.woff2') format('woff2'), url('../fonts/FiraSans-MediumItalic.woff') format('woff'), url('../fonts/FiraSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Arimo';
  src: url('../fonts/Arimo-Bold.eot');
  src: url('../fonts/Arimo-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Arimo-Bold.woff') format('woff'), url('../fonts/Arimo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.ar {
  font-family: 'Arimo', sans-serif;
}

.fs {
  font-family: 'Fira Sans', sans-serif;
}

.fsb {
  font-family: 'Fira Sans Bold', sans-serif;
}

.fsi {
  font-family: 'Fira Sans Italic', sans-serif;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.debug {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.debug > div {
  height: inherit;
  max-width: 1080px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.debug > div > div {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.debug > div > div > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(8.333333333333334% - 30px);
  height: inherit;
  outline: 1px solid #ff0;
  background: rgba(0, 0, 0, 0.5);
}

html {
  font-size: 10px;
  font-family: 'Fira Sans', sans-serif;
  color: #fefefe;
  background-color: #ebebeb;
}

*::selection {
  color: #35cbc8;
  background-color: #000;
}

*:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

:focus {
  outline: none;
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 100px 0;
}

a {
  color: #fefefe;
  text-decoration: none;
  font-size: 1.8rem;
  transition: all .6s;
}

a:hover {
  color: #35cbc8;
}

p {
  font-family: 'Fira Sans', sans-serif;
  font-size: 2rem;
}

h1 {
  font-size: 5.5rem;
  font-weight: 700;
  color: #35cbc8;
  text-transform: uppercase;
}

h1.page-name {
  font-size: 4rem;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 40px;
}

h2 {
  font-size: 4rem;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 40px;
}

h3 {
  font-size: 2.2rem;
  color: #35cbc8;
}

h1,
h2,
h3,
h4 {
  font-family: 'Arimo', sans-serif;
}

.result {
  background-color: #181818;
  position: fixed;
  margin-top: 80px;
  top: 0;
  right: 0;
  width: 200px;
  height: 100px;
  z-index: 9999;
  justify-content: center;
  text-transform: uppercase;
  color: #35cbc8;
  display: none;
}

.result .result-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
}

.result .result-wrapper img {
  display: none;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.result .result-wrapper p {
  font-family: 'Arimo', sans-serif;
}

.line {
  display: block;
  width: 71px;
  height: 4px;
  background-color: #35cbc8;
}

.colored {
  color: #35cbc8;
  font-weight: bold;
}

.white-text {
  color: #fefefe;
}

.btn-blue {
  padding: 15px 50px;
  background-color: #35cbc8;
  font-size: 2rem;
  font-family: 'Arimo', sans-serif;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  z-index: 100;
  transition: all .4s;
}

.btn-blue:hover {
  background-color: #fefefe;
}

.btn-trans {
  padding: 15px 60px;
  font-size: 2rem;
  cursor: pointer;
  color: #fefefe;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #35cbc8;
  transition: all .4s;
}

.btn-trans:hover {
  border: 1px solid #fefefe;
  background-color: #fefefe;
  opacity: 0.8;
  color: #35cbc8;
}

input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ebebeb;
  font-size: 2rem;
  width: 100%;
  color: #fefefe;
}

.input-line {
  position: relative;
  margin: 10px 30px;
}

.effect-2 {
  border: 0;
  border-bottom: 1px solid #fefefe;
}

.effect-2 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #35cbc8;
  transition: 0.4s;
}

.effect-2:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

#scroll_icon a {
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  padding-bottom: 40px;
  bottom: 0;
}

#scroll_icon a span {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fefefe;
  border-bottom: 2px solid #fefefe;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }

  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }

  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}

@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }

  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }

  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 80px;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#menu .menu-logo {
  position: absolute;
  left: 50%;
  margin-left: -50px;
}

#menu .menu-logo img {
  width: 100px;
}

#menu .burger-container {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 20px;
}

#menu .burger-container:hover {
  background-color: #181818;
  cursor: pointer;
}

#menu .burger-container p {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'Fira Sans Bold', sans-serif;
}

#menu .burger-toggle {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 54px;
  height: 48px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: #000;
}

#menu .burger-hover {
  background-color: #181818;
}

#menu .burger-toggle span {
  display: block;
  position: absolute;
  top: 23px;
  left: 9px;
  right: 9px;
  height: 3px;
  background: #fefefe;
}

#menu .burger-toggle span::before,
#menu .burger-toggle span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fefefe;
  content: "";
}

#menu .burger-toggle span::before {
  top: -10px;
}

#menu .burger-toggle span::after {
  bottom: -10px;
}

#menu .burger-toggle-switch span::before,
#menu .burger-toggle-switch span::after {
  transition-duration: 0.4s, 0.4s;
  transition-delay: 0.4s, 0;
}

#menu .burger-toggle-switch span::before {
  transition-property: top, transform;
}

#menu .burger-toggle-switch span::after {
  transition-property: bottom, transform;
}

#menu .burger-toggle-switch.active span {
  background: none;
}

#menu .burger-toggle-switch.active span::before {
  top: 0;
  transform: rotate(45deg);
}

#menu .burger-toggle-switch.active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

#menu .burger-toggle-switch.active span::before,
#menu .burger-toggle-switch.active span::after {
  transition-delay: 0, 0.4s;
}

#menu .menu-burger-items {
  position: fixed;
  margin-left: -600px;
  display: none;
  opacity: 0;
  top: 80px;
  background-color: #ebebeb;
  padding: 20px 30px;
  z-index: 9999;
  transition: all ease 0.3s;
}

#menu .menu-burger-items li {
  padding: 10px 0;
}

#menu .menu-burger-items a {
  font-size: 2rem;
  color: #000;
  text-transform: uppercase;
}

#menu .menu-burger-items a:hover {
  color: #35cbc8;
}

#menu .menu-contacts {
  padding-right: 20px;
}

#menu .menu-contacts .menu-contacts-phone {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

#menu .menu-contacts .menu-contacts-phone img {
  max-width: 20px;
  padding: 0 5px;
}

#menu .menu-contacts .menu-contacts-phone p {
  font-family: 'Arimo', sans-serif;
  font-size: 1.7rem;
}

#menu .menu-contacts .menu-contacts-geo {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

#menu .menu-contacts .menu-contacts-geo img {
  max-width: 20px;
  padding: 0 5px;
}

#menu .menu-contacts .menu-contacts-geo p {
  font-family: 'Arimo', sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
}

section#main-page {
  margin-top: 80px;
  position: relative;
  height: 90rem;
  padding: 0;
}

section#main-page .main-page-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  height: 100%;
}

section#main-page .item-biggest {
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(50% - 30px);
  height: 100%;
  background: url(../img/gtr.jpg) left center no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section#main-page .item-biggest .bg-item-text {
  padding: 0 30px;
}

section#main-page .item-biggest h1 {
  padding: 20px 0;
  color: #35cbc8;
}

section#main-page .item-biggest .h1sub {
  font-size: 3.5rem;
  text-transform: uppercase;
}

section#main-page .item-biggest .line {
  margin-top: 10px;
  margin-bottom: 30px;
}

section#main-page .item-biggest .little-text {
  text-transform: uppercase;
}

section#main-page .item-smallest {
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(50% - 30px);
}

section#main-page .item-smallest .second-level-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  height: 45rem;
}

section#main-page .item-smallest .second-level-items .level-up {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  width: 100%;
}

section#main-page .item-smallest .second-level-items .level-down {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  width: 100%;
}

section#main-page .item-smallest .second-level-items .item {
  background-color: #35cbc8;
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(50% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

section#main-page .item-smallest .second-level-items .item:hover {
  background-color: #fefefe;
}

section#main-page .item-smallest .second-level-items .item .item-wrapper {
  width: 100%;
}

section#main-page .item-smallest .second-level-items .item .item-wrapper #svg-image-menu {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

section#main-page .item-smallest .second-level-items .item .item-wrapper #svg-image-menu img {
  width: 100px;
}

section#main-page .item-smallest .second-level-items .item a {
  color: #000;
  text-transform: uppercase;
  font-family: 'Arimo', sans-serif;
  width: 100%;
}

section#main-page .item-smallest .second-level-items .item p {
  font-size: 2rem;
}

section#main-page .item-smallest .first-level-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  height: 45rem;
  background-color: #000;
}

section#main-page .item-smallest .first-level-items .item-1 {
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  background: url(../img/4-slide.jpg) center center no-repeat;
  height: 45rem;
}

section#main-page .item-smallest .first-level-items .item-2 {
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  height: 45rem;
  background: url(../img/4-slide.jpg) center center no-repeat;
}

section#main-page .item-smallest .first-level-items .item-3 {
  word-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  height: 45rem;
  background: url(../img/4-slide.jpg) center center no-repeat;
}

section#main-page .item-smallest .first-level-items .item-wrapper {
  margin-top: 10%;
  margin-left: 10%;
}

section#main-page .item-smallest .first-level-items .item-wrapper h3 {
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'Fira Sans Italic', sans-serif;
}

section#main-page .item-smallest .first-level-items .item-wrapper p {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: 'Fira Sans Italic', sans-serif;
}

section#advantages {
  background: url(../img/background.jpg) center no-repeat;
}

section#advantages .items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

section#advantages .item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  margin: 10px;
  padding-bottom: 15px;
  background-color: #181818;
}

section#advantages .item .item-image {
  display: flex;
  justify-content: center;
  padding: 30px;
}

section#advantages .item .item-image img {
  width: 130px;
  height: 130px;
}

section#advantages .item .item-option {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px;
}

section#advantages .item .line {
  margin: 10px 0;
}

section#advantages .item .item-descr {
  padding-top: 20px;
}

section#about-chip {
  background-color: #181818;
}

section#about-chip h2 {
  color: #fefefe;
}

section#about-chip .about-chip-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

section#about-chip .items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

section#about-chip .items .item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
  position: relative;
}

section#about-chip .items .item .item-number {
  font-size: 10rem;
  opacity: 0.15;
  color: #fefefe;
  font-family: 'Fira Sans Italic', sans-serif;
}

section#about-chip .items .item .item-name {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  color: #35cbc8;
  top: 50px;
  left: 20px;
}

section#about-chip .items .item .item-name p {
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-family: 'Fira Sans Italic', sans-serif;
}

section#about-chip .about-chip-content-descr {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  margin-top: 30px;
}

section#about-chip .about-chip-content-descr a {
  font-size: 2rem;
  color: #35cbc8;
}

section#about-chip .about-chip-content-descr a:hover {
  color: #fefefe;
}

section#about-chip .about-chip-content-descr .about-chip-descrip-modal {
  display: none;
}

section#about-chip .about-chip-content-descr .about-chip-descrip-modal ul {
  padding: 10px 0;
}

section#about-chip .about-chip-content-descr .about-chip-descrip-modal li {
  padding: 10px 0;
}

section#contacts {
  background-color: #ebebeb;
  position: relative;
}

section#contacts .container {
  position: relative;
}

section#contacts .contact-wrapper {
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

section#contacts .contact-wrapper .contacts-left {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

section#contacts .contact-wrapper .contacts-left .contact-item {
  display: flex;
  align-items: center;
  margin: 10px 10px;
}

section#contacts .contact-wrapper .contacts-left .contact-item p {
  text-transform: uppercase;
  color: #000;
  font-family: 'Arimo', sans-serif;
  font-size: 2.3rem;
  font-weight: bold;
}

section#contacts .contact-wrapper .contacts-left .contact-item a {
  font-size: 2.3rem;
  font-weight: bold;
  color: #000;
}

section#contacts .contact-wrapper .contacts-left .contact-item a:hover {
  color: #35cbc8;
}

section#contacts .contact-wrapper .contacts-left .contact-item img {
  margin: 0 10px;
}

section#contacts .contact-wrapper .contacts-form {
  background-color: #000;
  padding: 20px;
  z-index: 99;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

section#contacts .contact-wrapper .contacts-form h3 {
  text-align: center;
  text-transform: uppercase;
}

section#contacts .contact-wrapper .contacts-form .input-line {
  margin: 17px 30px;
}

section#contacts .contact-wrapper .contacts-form .button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
}

section#contacts .map-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
  height: 450px;
  filter: grayscale(100%);
}

section#contacts .confidencial {
  display: flex;
  margin: 17px 30px;
  align-self: center;
}

section#contacts .confidencial p {
  font-size: 1.8rem;
  line-height: 20px;
}

section#contacts .ckbx-style-2 {
  margin-right: 10px;
}

section#contacts .red {
  border: 1px solid red;
}

#footer {
  z-index: 998;
  overflow: hidden;
  background-color: #181818;
}

#footer .container {
  padding: 20px 0;
  padding-bottom: 10px;
}

#footer .footer-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

#footer .menu-items {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  padding-bottom: 10px;
}

#footer .menu-items a {
  font-size: 1.8rem;
  color: #fefefe;
}

#footer .menu-items a:hover {
  color: #35cbc8;
}

#footer .menu-items .logo {
  width: 100px;
  padding: 20px 0;
}

#footer .contacts-item {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

#footer .contacts-item p {
  text-transform: uppercase;
  font-size: 1.8rem;
}

#footer .contacts-item a {
  font-size: 1.8rem;
  color: #fefefe;
}

#footer .contacts-item a:hover {
  color: #35cbc8;
}

#footer .contacts-item .menu-contacts {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

#footer .contacts-item .menu-contacts img {
  margin: 5px 0;
  margin-right: 5px;
}

#footer .soc-items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  padding: 20px 0;
}

#footer .soc-items p {
  font-size: 1.8rem;
}

#footer .soc-items .soc-items-logos {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

#footer .soc-items .soc-items-wrapper {
  height: 40px;
  width: 40px;
  margin: 10px 10px;
  border: 2px solid #fefefe;
  border-radius: 3px;
}

#footer .soc-items .soc-items-wrapper:hover {
  border: 2px solid #35cbc8;
}

#footer .soc-items .soc-items-wrapper img {
  line-height: 30px;
  margin: 5px;
  width: 30px;
  height: 30px;
}

#footer .soc-items .soc-items-wrapper:last-child {
  margin-right: 0;
}

#footer .soc-items #vk_groups {
  margin-top: 10px;
}

#footer .dev-wrapper {
  background-color: #000;
}

#footer .dev-wrapper p {
  font-size: 1.4rem;
}

#footer .dev-wrapper a {
  font-size: 1.4rem;
}

#footer .copyright {
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

#footer .button-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 998;
  display: none;
}

#footer .button-top img {
  max-width: 30px;
  line-height: 100%;
  border: 2px solid #fefefe;
  border-radius: 30px;
  padding: 8px;
  transition: all ease 0.4s;
}

#footer .button-top img:hover {
  border: 2px solid #35cbc8;
  transform: translateY(-10px);
}

#footer-mobile {
  z-index: 998;
  width: 100%;
  color: #fefefe;
  display: none;
}

#footer-mobile .copyright {
  padding: 5px 0;
  text-align: center;
  background-color: #000;
}

#footer-mobile .copyright p {
  font-size: 1.6rem;
}

@media (min-width: 2000px) {
  section#main-page .item-smallest .first-level-items .item-wrapper {
    margin-left: 20%;
  }
}

@media screen and (max-width: 960px) {
  section#questions .questions {
    justify-content: center;
  }

  section#questions .questions .questions-bmw img {
    max-width: 300px;
  }

  section#price .container .cars-wrapper .car-wrapper-item {
    width: calc(50% - 30px);
  }

  section#sales .container .sales-today .sales-today-item .slide-description {
    width: calc(100% - 30px);
  }

  section#sales .container .sales-today .sales-today-item .slide-img {
    width: calc(100% - 30px);
  }

  section#sales .container .sales-today .sales-today-item .slide-img {
    justify-content: center;
  }

  section#sales .container .sales-today .sales-today-item .slide-img img {
    max-width: 200px;
  }

  section#sales-ended-section .sales-ended .sales-ended-item {
    width: calc(50% - 30px);
  }

  .error-page img {
    max-width: 300px;
  }

  section#reviews .reviews-items .reviews-item {
    width: 25%;
  }

  section#reviews .reviews-items .reviews-item--width2 {
    width: 50%;
  }

  section#blog .item-article .item-article-container .article-container-img {
    width: calc(50% - 30px);
  }

  section#blog .item-article .item-article-container .article-container-text {
    width: calc(50% - 30px);
  }

  section#article .container .article-img img {
    max-width: 500px;
  }

  html {
    font-size: 9px;
  }

  section {
    padding: 50px 0;
  }

  #scroll_icon a {
    display: none;
  }

  #menu .menu-logo {
    display: none;
  }

  section#main-page .item-biggest {
    width: calc(100% - 30px);
  }

  section#main-page .item-biggest .bg-item-text {
    font-size: 2rem;
  }

  section#main-page .item-smallest {
    display: none;
  }

  section#advantages .item {
    width: calc(50% - 30px);
  }

  section#advantages .item .item-option h3 {
    font-size: 2.5rem;
  }

  section#about-chip {
    height: 100%;
  }

  section#about-chip .items {
    width: calc(100% - 30px);
  }

  section#about-chip .about-chip-content-descr {
    width: calc(100% - 30px);
  }

  section#about-chip .about-chip-content-descr {
    padding: 30px 0;
  }

  section#contacts .contact-wrapper {
    padding-bottom: 30px;
  }

  section#contacts .contact-wrapper .contacts-left {
    width: calc(83.33333333333334% - 30px);
  }

  section#contacts .contact-wrapper .contacts-form {
    width: calc(83.33333333333334% - 30px);
  }

  section#contacts .contact-wrapper .contacts-form {
    justify-content: center;
  }

  section#contacts .map-wrapper {
    display: none;
  }

  #footer {
    display: none;
  }

  #footer-mobile {
    display: block;
  }
}

@media screen and (max-width: 780px) {
  section#questions .questions .questions-main {
    width: calc(100% - 30px);
  }

  section#questions .questions .questions-main .question {
    font-size: 1.7rem;
  }

  section#questions .questions .questions-main .answers {
    font-size: 1.7rem;
  }

  section#questions .questions .questions-main .plus {
    margin-top: 12px;
  }

  section#sales-ended-section .sales-ended .sales-ended-item {
    width: calc(100% - 30px);
  }

  section#reviews .reviews-items .reviews-item {
    width: 50%;
  }

  section#blog .item-article .item-article-container .article-container-img {
    width: calc(100% - 30px);
  }

  section#blog .item-article .item-article-container .article-container-text {
    width: calc(100% - 30px);
  }

  section#blog .item-article .item-article-container .article-container-text a {
    margin-top: 20px;
  }

  section#article .container .article-img img {
    max-width: 300px;
  }

  .modal-vk-wrapper .modal-vk {
    padding: 15px;
    width: 280px;
    height: 130px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  html {
    font-size: 6px;
  }

  .result {
    width: 100%;
    height: 50px;
  }

  .result .result-wrapper {
    margin-top: 18px;
  }

  .result .result-wrapper img {
    width: 15px;
    height: 15px;
  }

  #menu .menu-logo img {
    display: none;
  }

  #menu .burger-container p {
    display: none;
  }

  #menu .menu-burger-items a {
    font-size: 3rem;
  }

  #menu .menu-contacts .menu-contacts-phone p {
    font-size: 3.5rem;
  }

  #menu .menu-contacts .menu-contacts-geo p {
    font-size: 2rem;
    text-transform: uppercase;
  }

  section#main-page .item-biggest {
    background-position: right center;
  }

  section#about-chip .items .item {
    margin-bottom: 20px;
  }

  section#about-chip .items .item .item-name {
    top: 30px;
  }

  #footer .button-top {
    right: 20px;
  }

  #footer .button-top img {
    max-width: 16px;
  }
}

@media screen and (max-width: 560px) {
  section#advantages .item {
    width: calc(100% - 30px);
  }
}